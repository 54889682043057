import httpManager from "../axios/httpManager";

export function getLeisureGameList  (search, pageNum, pageSize, orderBy) {
    let url = '/leisuergame/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}
export function getLeisureGameEndList  (kind, pageNum, pageSize, orderBy) {
    let url = '/leisuergame/endlist' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&kind=' + kind;
    return httpManager.get(url);
}
export function saveManualResult  (leisureGame) {
    return httpManager.post('/leisuergame/save_manual_result', leisureGame);
}
export function getById  (id) {
    return httpManager.get('/leisuergame/get_by_id?id=' + id)
}
export function updateLeisureGame  (leisureGame) {
    return httpManager.post('/leisuergame/update', leisureGame);
}
export function updateLeisureGameStatus  (leisureGame) {
    return httpManager.post('/leisuergame/update_status', leisureGame);
}

export function updateLeisureGameDisplay  (leisureGame) {
    return httpManager.post('/leisuergame/update_display', leisureGame);
}

export function updateLeisureGameResultCancel  (leisureGame) {
    return httpManager.post('/leisuergame/update_result_cancel', leisureGame);
}

export function initLeisureGame  (id) {
    return  httpManager.get("/leisuergame/init_game?id=" + id);
}

export function liquidationLeisureGame  () {
    httpManager.get("/leisure/bet/liquidation");
}
