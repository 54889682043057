<template>
    <div class="sports_bet_list">
        <table class="table_sports_bet" :class="{'border_inplay':item.isinplay == managerConst.YES}" v-for="item in betList">
            <tr>
                <th style="width: 5%">번호</th>
                <th style="width: 5%">베팅종류</th>
                <th style="width: 8%">회원</th>
                <th style="width: 9%">베팅시간</th>
                <th style="width: 8%">베팅금</th>
                <th style="width: 5%">배당</th>
                <th style="width: 10%">예상적중금</th>
                <th style="width: 7%">베팅결과</th>
                <th style="width: 5%">정산상태</th>
                <th style="width: 21%">베팅IP</th>
                <th style="width: 17%">action</th>
            </tr>
            <tr>
                <td>{{item.id}}</td>
                <td>
                    <span v-if="item.type==managerConst.SportsBet.BET_TYPE_CROSS">크로스</span>
                    <span v-if="item.type==managerConst.SportsBet.BET_TYPE_1X2">승무패</span>
                    <span v-if="item.type==managerConst.SportsBet.BET_TYPE_HO">핸/오언</span>
                    <span v-if="item.type==managerConst.SportsBet.BET_TYPE_SPECIAL">스페셜</span>
                    <span v-if="item.type==managerConst.SportsBet.BET_TYPE_LIVE">라이브</span>
                    <span v-if="item.isinplay==managerConst.YES" class="bg-blue">INPLAY</span>
                </td>
                <td @click="editUser(item.userId,managerConst.MODE_EDIT)" style="cursor: pointer">
                    <span class="badge badge-success">{{item.user.nickname}}</span>
                </td>
                <td>{{item.createTime|datef('MM/DD HH:mm:ss')}}</td>

                <td :class="{'text-blue':item.betCash >= 100000}">{{item.betCash|comma}}</td>
                <td>{{item.betOddsTotal}}</td>
                <td :class="{'text-blue':item.totalCash >= 100000}">{{(item.totalCash) | comma}}</td>
                <td>
                    <span class="" v-if="item.betResult == managerConst.SportsBet.BET_RESULT_WAITING">결과대기</span>
                    <span class="badge badge-primary"
                          v-if="item.betResult == managerConst.SportsBet.BET_RESULT_WIN">당첨</span>
                    <span  class="badge badge-danger" v-if="item.betResult == managerConst.SportsBet.BET_RESULT_LOSE">낙첨</span>
                    <span class="badge badge-danger"
                          v-if="item.betResult == managerConst.SportsBet.BET_RESULT_SPECIALCASE">적특</span>
                    <span class="badge badge-danger" v-if="item.betResult == managerConst.SportsBet.BET_RESULT_CANCEL">취소</span>

                    <span class="" v-if="item.cancelbyuser == managerConst.YES">(회원)</span>

                    <span class="badge badge-warning" v-if="item.inited == managerConst.YES">(수동초기화됨)</span>
                    <br/><span class="badge badge-warning" v-if="item.operator">{{item.operator}}</span>
                </td>
                <td>
                    <span v-if="item.status==managerConst.SportsBet.STATUS_WAITING">정산대기</span>
                    <span v-if="item.status==managerConst.SportsBet.STATUS_COMPLITE">정산완료</span>
                </td>
                <td>{{item.betIp}}</td>
                <td style="text-align: right">
                    <el-button
                            v-if="item.betResult != managerConst.SportsBet.BET_RESULT_CANCEL && item.status==managerConst.SportsBet.STATUS_WAITING"
                            size="mini"
                            type="danger" @click="cancelBet(item.id)">취소
                    </el-button>
                    <el-button v-if="item.display == managerConst.ENABLE" size="mini" type="info"
                               @click="updateDisplay(item.id,managerConst.DISABLE)">삭제
                    </el-button>
                    <el-button v-if="item.display == managerConst.DISABLE" size="mini" type="info"
                               @click="updateDisplay(item.id,managerConst.ENABLE)">삭복
                    </el-button>
                    <el-button v-if="item.alarm == managerConst.UNCHECKED" size="mini" type="warning"
                               @click="updateAlarm(item.id,managerConst.CHECKED)">특확인
                    </el-button>
                    <el-button v-if="item.ishighamount == managerConst.YES" size="mini" type="warning"
                               @click="updateHighAmountBet(item.id,managerConst.DISABLE)">고액확인
                    </el-button>
                    <el-button type="success" size="mini"
                               v-if="item.betResult != managerConst.SportsBet.BET_RESULT_WAITING && item.isinplay==managerConst.YES"
                               @click="initBetInfo(item.id)">초기화
                    </el-button>
                </td>

            </tr>
            <tr>
                <td colspan="11">
                    <table class="table_bet_games" v-if="item.isinplay === managerConst.NOT">
                        <tr>
                            <td class="text-center" style="width: 7%;">ID</td>
                            <td class="text-center" style="width: 10%;">경기시간</td>
                            <td class="text-center" style="width: 13%">리그</td>
                            <td class="text-center" style="width: 25%">홈팀</td>
                            <td class="text-center" style="width: 8%;">
                                무/핸/오언
                            </td>
                            <td class="text-center" style="width: 25%">원정팀</td>
                            <td class="text-center" style="width: 6%;">경기결과</td>
                            <td class="text-center" style="width: 6%;">베팅결과</td>
                        </tr>
                        <tr v-for="bg in item.betInfoGameList"
                            :class="{'bg-warning':bg.game.autoclosed === managerConst.NOT}">
                            <td>
                                {{bg.game.id}}
                            </td>
                            <td>{{bg.game.startTime|datef('MM-DD HH:mm')}}</td>
                            <td style="text-align: left">
                                <img :src="bg.game.league.imageUrl"
                                     style="width: 28px;;vertical-align: middle">
                                <span style="display: inline-block;margin-left: 2px">{{bg.game.league.leagueName}}</span>
                            </td>
                            <td :class="{'bg-success':bg.selectedVal == managerConst.Game.GAME_SELECT_HOME}"
                                style="display: flex;justify-content: flex-start;align-items: center">
                                   <span style="width: 70%;text-align: left;padding-left: 3px"
                                         v-html="bg.game.homeTeamName"></span>
                                <span style="width: 30%;text-align: right;padding-right: 3px">
                                          <i v-if="bg.game.kind == managerConst.Game.GAME_KIND_HANDICAP"
                                             class="fa fa-h-square"></i>
                                            <i v-if="bg.game.kind == managerConst.Game.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-circle-up"></i>
                                    {{bg.homeTeamOdds}}
                                </span>
                            </td>
                            <td :class="{'bg-success':bg.selectedVal == managerConst.Game.GAME_SELECT_DRAW}">
                                 <span v-if="bg.game.kind == managerConst.Game.GAME_KIND_1X2">
                                                            <span v-if="bg.drawOdds > 0">{{bg.drawOdds}}</span>
                                                            <span v-if="bg.drawOdds <= 0">vs</span>
                                                        </span>
                                <span class="text-red"
                                      v-if="bg.game.kind == managerConst.Game.GAME_KIND_HANDICAP">{{bg.handicapVal}}</span>
                                <span class="text-blue"
                                      v-if="bg.game.kind == managerConst.Game.GAME_KIND_OVERUNDER">{{bg.overunderVal}}</span>
                            </td>
                            <td :class="{'bg-success':bg.selectedVal == managerConst.Game.GAME_SELECT_AWAY}"
                                style="display: flex;justify-content: flex-start;align-items: center">

                                <span style="width: 30%;text-align: left;padding-left: 3px">
                                    {{bg.awayTeamOdds}}
                                     <i v-if="bg.game.kind == managerConst.Game.GAME_KIND_HANDICAP"
                                        class="fa fa-h-square"></i>
                                <i v-if="bg.game.kind == managerConst.Game.GAME_KIND_OVERUNDER"
                                   class="fa fa-arrow-circle-down"></i>

                                </span>
                                <span style="width: 70%;text-align: right;padding-right: 3px"
                                      v-html="bg.game.awayTeamName"></span>
                            </td>
                            <td>
                                <span v-if="bg.game.gameResult == managerConst.Game.GAME_RESULT_WATING">대기중</span>
                                <span v-if="bg.game.gameResult != managerConst.Game.GAME_RESULT_WATING">{{bg.game.homeTeamScore + ':'+bg.game.awayTeamScore}}</span>
                            </td>
                            <td>
                                <span v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                                <span class="badge badge-primary"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_WIN">당첨</span>
                                <span  class="badge badge-danger"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_LOSE">낙첨</span>
                                <span class="badge badge-danger"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_SPECIALCASE">적특</span>
                                <span class="badge badge-danger"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_CANCEL">취소</span>
                            </td>
                        </tr>
                    </table>

                    <table class="table_bet_games" v-if="item.isinplay === managerConst.YES">
                        <tr>
                            <th class="text-center" style="width: 7%;">ID</th>
                            <th class="text-center" style="width: 7%;">게임ID</th>
                            <th class="text-center" style="width: 10%;">경기시간</th>
                            <th class="text-center" style="width: 13%">리그</th>
                            <th class="text-center">팀명</th>
                            <th>승부타임</th>
                            <th>구분</th>
                            <th>선택항목</th>
                            <th>선택배당</th>
                            <th>베팅결과</th>
                        </tr>
                        <tr v-for="bg in item.betInfoGameList">
                            <td class="text-center" style="width: 7%;">{{bg.id}}</td>
                            <td class="text-center" style="width: 7%;">{{bg.game.id}}</td>
                            <td class="text-center" style="width: 10%;">{{bg.game.startTime|datef('MM/DD HH:mm')}}
                            </td>
                            <td class="text-left" style="width: 13%">
                                <img :src="bg.game.league.imageUrl"
                                     style="width: 28px;vertical-align: middle">
                                <span>{{bg.game.league.leagueName}}</span>
                            </td>
                            <td>
                                <span>{{bg.game.homeTeamName}}</span>
                                <span class="text-primary">vs</span>
                                <span>{{bg.game.awayTeamName}}</span>
                            </td>
                            <td>{{bg.ito1}}</td>
                            <td>{{bg.ito2}}</td>
                            <td class="text-blue" style="font-weight: bold">{{bg.ito3}}</td>
                            <td :class="{'bg-warning':bg.err == managerConst.YES}">{{bg.selectedOdds}}
                                <span v-if="bg.score != null">({{bg.score}})</span>
                                <span v-if="bg.err == managerConst.YES" class="text-danger">
                                                        <i class="fa fa-question-circle"></i>
                                                        <i class="fa fa-question-circle"></i>
                                                        <i class="fa fa-question-circle"></i>
                               </span>
                            </td>
                            <td>
                                                    <span v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                                <span class="badge badge-primary"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_WIN">당첨</span>
                                <span class="badge badge-danger"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_LOSE">낙첨</span>
                                <span class="badge badge-danger"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_SPECIALCASE">적특</span>
                                <span class="badge badge-danger"
                                      v-if="bg.betGameResult == managerConst.SportsBet.BET_RESULT_CANCEL">취소됨</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>

    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import Vue from "vue";
    import UserEditToast from "./UserPanel/UserEditToast";
    import {
        initSportsBetInfo,
        updateSportsBetInfo,
        updateSportsBetInfoResultCancel
    } from "../../network/manager/sportsBetRequest";
    import {updateLeisureGameResultCancel} from "../../network/manager/leisureRequest";
    import {updateGroup} from "../../network/manager/groupRequest";

    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "SportsBetInfoComp",
        data() {
            return {
                managerConst: managerConst,
                betList: [],
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },

        },
        methods: {
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            updateDisplay(id, s) {
                this.$confirm('해당 베팅 내역은 업데이트 하시겠습니까?', '업데이트', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let update = {}
                    update.id = id;
                    update.display = s;
                    updateSportsBetInfo(update).then(res=>{
                        if (res.data.success) {
                            this.$emit('refresh')
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                });

            },
            updateAlarm(id, s) {
                let update = {}
                update.id = id;
                update.alarm = s;
                updateSportsBetInfo(update).then(res=>{
                    if (res.data.success) {
                        this.$emit('refresh')
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            updateHighAmountBet(id, s) {
                let update = {}
                update.id = id;
                update.ishighamount = s;
                updateSportsBetInfo(update).then(res=>{
                    if (res.data.success) {
                        this.$emit('refresh')
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            cancelBet(id) {
                this.$confirm('해당 베팅내역을  취소 하시겠습니까?', '베팅내역 취소', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let update ={}
                    update.id = id
                    updateSportsBetInfoResultCancel(update).then(res=>{
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '초기화 완료되였습니다'
                            });
                            this.$emit('refresh')
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                })

            },
            initBetInfo(id) {
                this.$confirm('해당 베팅내역을  초기화 하시겠습니까?', '베팅내역 초기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    initSportsBetInfo(id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '초기화 완료되였습니다'
                            });
                            this.$emit('refresh')
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });
            },
        },
        watch: {
            list: {
                handler(newVal) {
                    this.betList = newVal
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>
    .sports_bet_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table_sports_bet {
        width: 100%;
        margin-bottom: 10px;
    }

    .table_bet_games {
        width: 100%;
    }

    .table_bet_games td {
        line-height: 40px !important;
        border-bottom: 1px solid #cfcfd1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px;
    }
</style>